import React from "react"
import Button from "../button"
import {getBucketUrl} from '../../service'
import Title from '../title'

const Institutions = (props) => {
  return (
    <div className="c-institutions">
      <div className="u-wrapper">
        <Title center={true} content="Instituições que investimos" />
        {props.cards.map(({ title, text, image, link }, index) => (
          <div className="c-institutions__item" key={index}>
            <img className="c-institutions__img" src={getBucketUrl(image.path)} alt={title} />
            <div className="c-institutions__infos">
              <h3 className="c-institutions__ttl o-ttl o-ttl--40">{title}</h3>
              <p className="c-institutions__txt">{text}</p>
              <Button
                href={link}
                content="Visitar site"
                type="primary"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Institutions;
