import React, { useEffect, useState } from "react"
import Layout from "components/layout/layout"
import BannerTopo from "../../components/bannerTopo"
import InfosPage from "../../components/InfosPage"
import Institutions from "../../components/institutions"
import Cards from "../../components/cards"
import Title from "../../components/title"
import TabsCards from '../../components/tabsCards'
import {getBucketUrl} from '../../service'

const Educacao = () => {

  const [api, setApi] = useState({});

  const init = (apiData) => {
    setApi(apiData);
  }

  useEffect(() => {
  }, []);

  return (
    <div>
      <Layout loadedCallback={init} pageTitle="Educação" pageDescription="Cursos, vídeos e leituras que ajudam nossos talentos a atingirem sua potência máxima.">
        {api && api.constructor === Object && Object.keys(api).length !== 0 && (
          <>
            <InfosPage titlePage="Educação" text={api.singletons.education.text} />
            <BannerTopo image={getBucketUrl(api.singletons.education.image.path)} />
            <div className="u-wrapper">
              <div className="c-start-here">
                <Title
                  content="Comece <strong>por aqui</strong>"
                  center={true}
                />
                <p className="o-headline u-text-center">Indicamos alguns vídeos que refletem bastante nossa maneira de pensar e agir.</p>
                <TabsCards tabs={api.collections.medias.map(item => {return {cards: item.medias, tab: item.title}})}/>
              </div>
            </div>
            <div className="u-wrapper">
              <div className="c-courses-free">
                <Title
                  content="<strong>Cursos gratuitos</strong> para se aprofundar"
                  center={true}
                />
                <p className="o-headline">Temos algumas sugestões de cursos online e gratuitos de grandes universidades no mundo que irão te ajudar no desenvolvimento pessoal. Aproveite!</p>
                  <div className="c-cards-wrapper">
                    <Cards
                      hasLink={true}
                      visible={6}
                      qtMore={3}
                      cards={api.collections.courses} />
                  </div>
              </div>
            </div>
            <Institutions
              cards={api.collections.institutions}
            />
          </>
        )}
      </Layout>
    </div>
  )
}

export default Educacao
