import React from "react"

const Title = props => {

  let classes = [];

  classes.push('o-ttl--' + (props.size ? props.size : '40'));

  if (props.center) {
    classes.push('u-text-center');
  }

  if (props.content.indexOf('<strong>') > -1) {
    classes.push('o-ttl--hybrid');
  }

  if (props.customModifiers && Array.isArray(props.customModifiers)) {
    props.customModifiers.forEach(element => {
      classes.push(`o-ttl--${element}`);
    });
  }

  if (props.customClasses && Array.isArray(props.customClasses)) {
    props.customClasses.forEach(element => {
      classes.push(element);
    });
  }

  const CustomTag = props.tag ? props.tag : "h2";

  return (
    <CustomTag
      className={`o-ttl ${classes.join(" ")}`}
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  )
}

export default Title
