import React, { useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Cards from "../cards"

const TabsCards = props => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        {props.tabs.map((res, index) => (
          <Tab
            key={index}
            label={res.tab}
          >
            {res.tab}
          </Tab>
        ))}
      </TabList>

      {props.tabs.map((res, index) => (
        <TabPanel key={index}>
          <div className="c-cards-wrapper">
            <Cards
              hasLink={true}
              hasImage={true}
              cards={res.cards}
              visible={6}
              qtMore={3}
            />
          </div>
        </TabPanel>
      ))}
    </Tabs>
  )
}

export default TabsCards
