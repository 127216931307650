import { Link } from 'gatsby';
import React from 'react';

function Button (props) {
  return (
    <>
      {props.href ? (
        <a href={props.href} className={`o-btn o-btn--${props.type}`} >{props.content}</a>
      ) : (
        <Link to={props.to} className={`o-btn o-btn--${props.type}`} >{props.content}</Link>
      )}
    </>
  )
}

export default Button;
