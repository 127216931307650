import React, { useEffect, useState } from "react"
import Card from './card';
import ConditionalLinkWrapper from '../../components/conditionalLinkWrapper';
import { getBucketUrl } from '../../service';

const Cards = props => {
  const [infosFiltered, setInfosFiltered] = useState([])
  const [visible, setVisible] = useState(props.visible)

  const loadMore = () => {
    setVisible(visible + props.qtMore);
  }

  function getLink(res) {
    if (props.hasLink) {
      if(res.hasOwnProperty("link")) {
        return res.link;
      }

      if(res.hasOwnProperty("slug")) {
        return (props.path ? props.path : "") + res.slug;
      }
    }
    return null;
  }

  function getIcon(result) {
    if(props.hasIcon) {
      if(result.icon.path.indexOf('http') === 0) {
        return result.icon.path;
      }
      return getBucketUrl(result.icon.path);
    }

    return null;
  }

  useEffect(() => {
    setInfosFiltered(props.cards);
  }, [props.cards])

  return (
    <>
      {infosFiltered.slice(0, visible).map((result, index) => (
        <ConditionalLinkWrapper link={getLink(result)} key={index}>
          <Card
            modifier={props.modifier === "team" ? (
              result.static ? [props.modifier, "team-static"] : [props.modifier]
            ) : (props.modifier) }
            data={result}
            icon={getIcon(result)}
            image={props.hasImage ? getBucketUrl(result.thumb.path) : null}
          />
        </ConditionalLinkWrapper>
      ))}
      {visible < infosFiltered.length && (
        <div className="u-text-center c-cards-wrapper__btn">
          <button onClick={loadMore} type="button" className="o-btn o-btn--primary">Carregar mais</button>
        </div>
      )}
    </>
  )
}

export default Cards
