import React from "react"

const Card = props => {

  function getText() {
    if (Array.isArray(props.data.text)) {
      return props.data.text.map(item => item.value).join("<br>");
    }

    return props.data.text;
  }

  function getModifiers() {
    if (props.modifier) {
      let ret = "";
      if (Array.isArray(props.modifier)) {
        props.modifier.forEach((item) => {
          ret += " c-card--" + item;
        })
      } else {
        ret = " c-card--" + props.modifier;
      }
      return ret;
    }
    return "";
  }
  return (
    <div className={`c-card${getModifiers()}`}>
      {props.icon && (
        <span className="c-card__ico">
          <img src={props.icon} alt={props.data.title} />
        </span>
      )}
      {props.image && (
        <img src={props.image} alt={props.data.title} />
      )}
      {props.data.category && (
        <span className="c-card__label">{props.data.category.display}</span>
      )}
      <div className="c-card__infos">
        {props.year && (
          <span className="c-card__year">{props.year}</span>
        )}
        <h3
          className="c-card__ttl"
          dangerouslySetInnerHTML={{ __html: props.data.title }}
        />
        <p
          className="c-card__txt"
          dangerouslySetInnerHTML={{ __html: getText()}}
        />
      </div>
      <span className="c-card__btn"></span>
    </div>
  )
}

export default Card
