import React from 'react';
import { Link } from 'gatsby';

const ConditionalLinkWrapper = ({ link, children }) => {
  function wrapper(children) {
    if (link.indexOf('http') === 0) {
      return <a className="c-card-link" href={link} target="_blank" rel="noreferrer">{children}</a>
    }

  return <Link className="c-card-link" to={link}>{children}</Link>
  }

  return link ? wrapper(children) : children;
}

export default ConditionalLinkWrapper;
